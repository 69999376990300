import React from "react";

const Summer = () => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h1>2025 Summer Season</h1>
      </div>
      <h3>What does the Summer season look like?</h3>
      <h5>Late May - End of June</h5>
      <div>
        <p style={{ textAlign: "left" }}>
          We field both "travel teams" and "practice teams" in the summer season.  Both 
          teams practice twice a week.  Tryouts are typically the weekend after high school
          season ends.</p>
          <p style={{ textAlign: "left" }}><strong><u>Travel teams</u></strong> compete in
          2 tournaments: a national qualifier in Los Angeles and the AAU National Championships in Orlando. Depending on the situation, athletes who cannot travel
          to both tournaments may be asked to practice with a practice team.
        </p>
        <p style={{ textAlign: "left" }}><strong><u>Practice teams</u></strong> do not travel
        to tournaments, but still practice alongside travel teams for one month before the national championships.
        The cost difference between the two teams are at the bottom of this page.
        </p>
        <p style={{ textAlign: "left"}}>
          There is a new tryout for Summer season - <strong>rosters from the previous
          fall season do <u>not</u> stay the same into summer season.</strong>
        </p>
      </div>
      <h3>2025 Tournaments Attended</h3>
          <h5>SoCal Showcase (Los Angeles Convention Center)</h5>
          June 13th - 15th, 2025 (All age groups)
          <h5>AAU National Championships</h5>
        June 30th - July 3rd 2025 (18u, 16u, 13u, 12u)
        <br />
        July 4th - July 7th 2025 (17u, 15u, 14u)
      <h3>Costs</h3>
      <p>
        Tryouts are $25. After making a team, dues will be structured as shown
        below:
      </p>
      <h4>Summer Practice Teams</h4>
      <p>
        Practice teams do not travel to any tournaments but still practice as a
        team for 5 weeks and may scrimmage other practice teams.
      </p>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Directly After Tryouts</td>
            <td>$400</td>
          </tr>
          <tr className="boldrow">
            <td>Practice Team Total</td>
            <td>$400</td>
          </tr>
        </tbody>
      </table>
      <h4>Summer Travel Teams</h4>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Directly After Tryouts</td>
            <td>$550</td>
          </tr>
          <tr>
            <td>June 1st</td>
            <td>$550</td>
          </tr>
          <tr className="boldrow">
            <td>Travel Team Total</td>
            <td>$1100</td>
          </tr>
        </tbody>
      </table>
      <p>
        NOTE: Travel/hotels are not covered with dues for travel teams. Travel team
        parents need to budget for travel/hotel expenses for both
        tournaments.
      </p>
    </div>
  );
};

export default Summer;
