import { CaptionImage, SWLink } from "../../components/components";

import { Carousel } from "react-responsive-carousel";

const CoachList = ({ type, coaches, team }) => {
  return (
    <h4 style={{ margin: "4px 0" }}>
      {type} Coach{coaches.length > 1 ? "es:" : ":"}{" "}
      {coaches.map((coach, index) => {
        const ref = `/staff/${team.year}/${team.season}${
          team.sub ? `-${team.sub}` : ""
        }/${coach.name.split(" ").join().replace(",", "").toLowerCase()}`;
        return (
          <span key={`${coach.name} link`}>
            {index !== 0 ? (
              <>
                {", "}
                <SWLink to={ref} content={coach.name} />
              </>
            ) : (
              <SWLink to={ref} content={coach.name} />
            )}
          </span>
        );
      })}
    </h4>
  );
};

const TeamView = ({ team }) => {
  return (
    <div className="coachView">
      <h2 style={{ margin: "2px 0" }}>
        {team.season} {team.teamName}
      </h2>
      <h5 style={{ margin: "2px 0" }}>
        {team.year} {team.sub ?? ""}
      </h5>
      {team.image ? (
        <CaptionImage src={team.image.src} caption={team.image.caption} />
      ) : null}
      {team.heads ? (
        <CoachList type={"Head"} coaches={team.heads} team={team} />
      ) : null}
      {team.assistants ? (
        <CoachList type={"Assistant"} coaches={team.assistants} team={team} />
      ) : null}
      {team.roster && team.roster.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                {team.roster[0].number ? <td>#</td> : null}
                <td>Name</td>
                {team.roster[0].school ? <td>School</td> : null}
                {team.roster[0].gradYear ? <td>Grad Year</td> : null}
                {team.roster[0].height ? <td>Height</td> : null}
              </tr>
            </thead>
            <tbody>
              {team.roster
                .sort((a, b) =>
                  team.roster[0].number
                    ? a.number < b.number
                      ? -1
                      : 1
                    : a.school < b.school
                    ? -1
                    : 1
                )
                .map((player, index) => {
                  return (
                    <tr key={`${index} ${player.name}`}>
                      {team.roster[0].number ? (
                        <td>{player.number ? player.number : ""}</td>
                      ) : null}
                      <td>{player.name}</td>
                      {team.roster[0].school ? (
                        <td>{player.school ? player.school : ""}</td>
                      ) : null}
                      {team.roster[0].gradYear ? (
                        <td>{player.gradYear ? player.gradYear : ""}</td>
                      ) : null}
                      {team.roster[0].height ? (
                        <td>{player.height ? player.height : ""}</td>
                      ) : null}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      ) : (
        <h3>Roster will be posted here at the conclusion of tryouts!</h3>
      )}
    </div>
  );
};

const TeamButton = ({ index, name, slide, setSlide }) => {
  return (
    <button
      className={"coachButton" + (slide == index ? " sel" : "")}
      onClick={() => setSlide(index)}
    >
      {name}
    </button>
  );
};

export const TeamSlider = ({ teams, slide, setSlide }) => {
  return (
    <>
      <div className="coachButtons">
        <div className="buttonGroup">
          {teams.map((team, index) => {
            return (
              <TeamButton
                index={index}
                name={team.teamName}
                slide={slide}
                setSlide={setSlide}
                key={`${team.teamName} button`}
              />
            );
          })}
        </div>
      </div>
      <hr />
      <div>
        <Carousel
          showThumbs={false}
          showArrows={false}
          showIndicators={false}
          infiniteLoop={true}
          selectedItem={slide}
          swipeScrollTolerance={50}
          preventMovementUntilSwipeScrollTolerance={true}
          onChange={(result) => {
            if (result != slide) {
              setSlide(result);
            }
          }}
        >
          {teams.map((team, index) => {
            return <TeamView team={team} key={`${team.teamName} view`} />;
          })}
        </Carousel>
      </div>
    </>
  );
};
