import "./Staff.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Dropdown } from "../../components/Dropdown";

const CoachButton = ({ index, name, teamName, slide, setSlide }) => {
  return (
    <button
      className={"coachButton" + (slide === index ? " sel" : "")}
      onClick={() => setSlide(index)}
    >
      {name}
    </button>
  );
};

const ButtonGroup = ({ title, coaches, slide, setSlide, startingIndex }) => {
  return (
    <>
      <h5>{title}</h5>
      <div className="buttonGroup">
        {coaches.map((coach, index) => {
          return (
            <CoachButton
              index={startingIndex + index}
              name={coach.name}
              teamName={""} //coach.team.teamName}
              slide={slide}
              setSlide={setSlide}
            />
          );
        })}
      </div>
    </>
  );
};

const CoachHeader = ({
  slide,
  setSlide,
  season,
  setSeason,
  sub,
  setSub,
  directors,
  headCoaches,
  assistCoaches,
}) => {
  const subs = [
    { label: "Summer", value: "Summer" },
    { label: "Fall", value: "Fall" },
  ];
  const handleSubChange = (event) => {
    setSlide(0);
    setSub(event.target.value);
  };

  const seasons = [
    { label: "Boys", value: "Boys" },
    { label: "Girls", value: "Girls" },
  ];
  const handleSeasonChange = (event) => {
    setSlide(0);
    setSeason(event.target.value);
  };
  return (
    <div className="coachButtons">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Dropdown
          label="Season:"
          options={seasons}
          value={season}
          onChange={handleSeasonChange}
          key={season}
          hideLabel
        />
        {/* {season === "Boys" ? (
          <Dropdown
            label="Sub:"
            options={subs}
            value={sub}
            onChange={handleSubChange}
            key={sub}
            hideLabel
          />
        ) : null} */}
      </div>
      <ButtonGroup
        title={"Directors"}
        coaches={directors}
        slide={slide}
        setSlide={setSlide}
        startingIndex={0}
      />
      <ButtonGroup
        title={"Head Coaches"}
        coaches={headCoaches.flat(1)}
        slide={slide}
        setSlide={setSlide}
        startingIndex={directors.length}
      />
      <ButtonGroup
        title={"Assistant Coaches"}
        coaches={assistCoaches.flat(1)}
        slide={slide}
        setSlide={setSlide}
        startingIndex={headCoaches.flat(1).length + directors.length}
      />
    </div>
  );
};

export default CoachHeader;
